import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SimpleHeader from './components/SimpleHeader';
import Forms from './components/form/Forms';
import Find from './components/find/Find';
import Orders from './components/Orders';
import KitaPartnerForm from './components/KitaPartnerForm';
import Manage from './components/Manage';

const AppContent = () => {
  useEffect(() => {
    // Set the html lang attribute based on the current language
    const updateHtmlLang = () => {
      document.documentElement.setAttribute('lang', i18next.language);
    };

    updateHtmlLang(); // Set initial language
    i18next.on('languageChanged', updateHtmlLang); // Update when language changes

    return () => {
      i18next.off('languageChanged', updateHtmlLang); // Clean up
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      window.UST_CT = [];
      window.UST = { s: Date.now(), addTag: function(tag) { window.UST_CT.push(tag) } };
      window.UST.addEvent = window.UST.addTag;

      const ust_s = document.createElement('STYLE');
      ust_s.id = 'ust_body_style';
      ust_s.appendChild(document.createTextNode('body {opacity: 0}'));
      document.head.appendChild(ust_s);

      setTimeout(function(){ 
        const el = document.getElementById('ust_body_style'); 
        el && el.remove();
      }, 800);

      const script1 = document.createElement('script');
      script1.src = "https://ui.kita.kids/server/ab/app.kita.kids.ab.js?v=6.5.0";
      script1.defer = true;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.src = "https://ui.kita.kids/server/ust-rr.min.js?v=6.5.0";
      script2.async = true;
      document.head.appendChild(script2);
    }
  }, []);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <SimpleHeader />

      {/* Hauptinhalt */}
      <main className="flex-grow px-4 sm:px-6 lg:px-8 py-8 max-w-7xl mx-auto w-full">
        <Routes>
          <Route path="/form" element={<Forms />} />
          <Route path="/find" element={<Find />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/voiio" element={<Forms />} />
          <Route path="/kitapartnerform" element={<KitaPartnerForm />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/benefitsme" element={<Forms />} />
          <Route path="*" element={<Navigate to="/form" replace />} />
        </Routes>
      </main>

      {/* Fußzeile */}
      <footer className="bg-gray-100 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-gray-600">
          <p className="text-lg mb-4">{t('footer.copyright', { year: new Date().getFullYear() })}</p>
          <div className="flex justify-center space-x-4">
            <a href="https://www.kita.kids/imprint-agb" className="text-blue-600 hover:text-blue-800">{t('footer.imprint')}</a>
            <a href="https://www.kita.kids/privacy" className="text-blue-600 hover:text-blue-800">{t('footer.privacy')}</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

const KitaKidsApp = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default KitaKidsApp;
