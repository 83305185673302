import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBuilding, FaPhone, FaEnvelope, FaGlobe, FaChild, FaClock, FaWhatsapp } from 'react-icons/fa';

const LocationModal = ({ isOpen, onClose, onSave, initialData }) => {
  const { t, i18n } = useTranslation();
  const [locationData, setLocationData] = useState({
    kitaName: '',
    address: {
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
    },
    phone: '',
    email: '',
    website: '',
    organizationType: '',
    organizationName: '',
    hasCommunalFeeLimit: false,
    minimumAge: '',
    childrenCount: '',
    groupCount: '',
    availablePlaces: [],
    careTypes: [],
    pedagogicalConcept: '',
    isAccessible: false,
    hasOutdoorArea: false,
    cateringOption: '',
    careTimeStart: '',
    careTimeEnd: '',
    transportation: [],
    closingDays: '',
    emergencyContact: '',
    additionalServices: '',
  });

  useEffect(() => {
    setLocationData(prevData => ({
      ...prevData,
      ...(initialData || {}),
      careTypes: initialData?.careTypes || [],
      transportation: initialData?.transportation || [],
    }));
  }, [initialData]);

  useEffect(() => {
    if (initialData) {
      setLocationData(initialData);
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocationData(prevState => {
      if (name === 'careTypes') {
        const updatedCareTypes = checked
          ? [...prevState.careTypes, value]
          : prevState.careTypes.filter(type => type !== value);
        return { ...prevState, careTypes: updatedCareTypes };
      }
      return {
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      };
    });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setLocationData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value
      }
    }));
  };

  const handleSave = () => {
    const requiredFields = [
      'kitaName',
      'address.street',
      'address.houseNumber',
      'address.postalCode',
      'address.city',
      'phone',
      'email',
      'organizationType',
      'organizationName',
      'minimumAge',
      'childrenCount',
      'groupCount',
      'careTypes',
      'pedagogicalConcept',
      'cateringOption',
      'careTimeStart',
      'careTimeEnd',
      'closingDays',
    ];

    const missingFields = requiredFields.filter(field => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return !locationData[parent][child];
      }
      if (field === 'careTypes') {
        return locationData[field].length === 0;
      }
      return !locationData[field];
    });

    if (missingFields.length > 0) {
      alert(t('locationModal.fillAllFields'));
      return;
    }

    onSave(locationData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-3 sm:p-5 border w-full sm:w-11/12 md:w-4/5 lg:w-3/4 xl:w-2/3 shadow-lg rounded-md bg-white">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-900"
          aria-label="Close"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <a
          href="https://wa.me/message/M5JJAV7WFWRXK1"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-0 right-0 mt-2 sm:mt-4 mr-14 sm:mr-16 inline-flex items-center justify-center px-2 sm:px-4 py-1 sm:py-2 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <FaWhatsapp className="mr-1 sm:mr-2" />
          <span>{t('locationModal.whatsappHelp')}</span>
        </a>
        <div className="mt-10">
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">{t('locationModal.title')}</h3>
          <div className="mt-2 px-4 py-3">
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Basic Information */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="text-md font-semibold mb-3">{t('locationModal.basicInfo')}</h4>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="kitaName" className="block text-sm font-medium text-gray-700">{t('locationModal.kitaName')}</label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FaBuilding className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          name="kitaName"
                          id="kitaName"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 text-base border-gray-300 rounded-md p-2"
                          placeholder={t('locationModal.kitaNamePlaceholder')}
                          value={locationData.kitaName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label htmlFor="street" className="block text-sm font-medium text-gray-700">{t('locationModal.street')}</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="street"
                            id="street"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md p-2"
                            value={locationData.address.street}
                            onChange={handleAddressChange}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="houseNumber" className="block text-sm font-medium text-gray-700">{t('locationModal.houseNumber')}</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="houseNumber"
                            id="houseNumber"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md p-2"
                            value={locationData.address.houseNumber}
                            onChange={handleAddressChange}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">{t('locationModal.postalCode')}</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md p-2"
                            value={locationData.address.postalCode}
                            onChange={handleAddressChange}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">{t('locationModal.city')}</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md p-2"
                            value={locationData.address.city}
                            onChange={handleAddressChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">{t('locationModal.phone')}</label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FaPhone className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 text-base border-gray-300 rounded-md p-2"
                          placeholder={t('locationModal.phonePlaceholder')}
                          value={locationData.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('locationModal.email')}</label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FaEnvelope className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 text-base border-gray-300 rounded-md p-2"
                          placeholder={t('locationModal.emailPlaceholder')}
                          value={locationData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="website" className="block text-sm font-medium text-gray-700">{t('locationModal.website')}</label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FaGlobe className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="url"
                          name="website"
                          id="website"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 text-base border-gray-300 rounded-md p-2"
                          placeholder={t('locationModal.websitePlaceholder')}
                          value={locationData.website}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Organization Information */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h4 className="text-md font-semibold mb-3">{t('locationModal.organizationInfo')}</h4>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="organizationType" className="block text-sm font-medium text-gray-700">{t('locationModal.organizationType')}</label>
                      <select
                        id="organizationType"
                        name="organizationType"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                        value={locationData.organizationType}
                        onChange={handleInputChange}
                      >
                        <option value="">{t('locationModal.selectOption')}</option>
                        <option value="private">{t('locationModal.private')}</option>
                        <option value="municipal">{t('locationModal.municipal')}</option>
                        <option value="religious">{t('locationModal.religious')}</option>
                        <option value="nonprofit">{t('locationModal.nonprofit')}</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="organizationName" className="block text-sm font-medium text-gray-700">{t('locationModal.organizationName')}</label>
                      <input
                        type="text"
                        name="organizationName"
                        id="organizationName"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-base border-gray-300 rounded-md p-2"
                        value={locationData.organizationName}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="hasCommunalFeeLimit"
                          name="hasCommunalFeeLimit"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          checked={locationData.hasCommunalFeeLimit}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="hasCommunalFeeLimit" className="font-medium text-gray-700">{t('locationModal.hasCommunalFeeLimit')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Capacity and Care Information */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="text-md font-semibold mb-3">{t('locationModal.capacityAndCareInfo')}</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="minimumAge" className="block text-sm font-medium text-gray-700">
                        {t('locationModal.minimumAge')}: {locationData.minimumAge}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="range"
                          name="minimumAge"
                          id="minimumAge"
                          min="0"
                          max="18"
                          step="0.5"
                          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                          value={locationData.minimumAge}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            handleInputChange({ target: { name: 'minimumAge', value: value.toFixed(1) } });
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="childrenCount" className="block text-sm font-medium text-gray-700">
                        {t('locationModal.childrenCount')}: {locationData.childrenCount}
                      </label>
                      <input
                        type="range"
                        name="childrenCount"
                        id="childrenCount"
                        min="0"
                        max="150"
                        step="1"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        value={locationData.childrenCount}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          handleInputChange({ target: { name: 'childrenCount', value: value.toString() } });
                        }}
                      />
                    </div>

                    <div>
                      <label htmlFor="groupCount" className="block text-sm font-medium text-gray-700">
                        {t('locationModal.groupCount')}: {locationData.groupCount}
                      </label>
                      <input
                        type="range"
                        name="groupCount"
                        id="groupCount"
                        min="0"
                        max="20"
                        step="1"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                        value={locationData.groupCount}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          handleInputChange({ target: { name: 'groupCount', value: value.toString() } });
                        }}
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">{t('locationModal.careTypes')}</label>
                      <div className="mt-2 space-y-2">
                        {['nursery', 'kindergarten', 'afterSchool', 'daycare', 'communityChildcare'].map((type) => (
                          <div key={type} className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id={type}
                                name="careTypes"
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                checked={locationData.careTypes.includes(type)}
                                onChange={(e) => {
                                  const updatedCareTypes = e.target.checked
                                    ? [...locationData.careTypes, type]
                                    : locationData.careTypes.filter(t => t !== type);
                                  setLocationData(prev => ({ ...prev, careTypes: updatedCareTypes }));
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor={type} className="font-medium text-gray-700">{t(`locationModal.${type}`)}</label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <h5 className="text-sm font-medium text-gray-700 mb-2">{t('locationModal.careTimes')}</h5>
                    <div className="flex space-x-4">
                      <div className="flex-1">
                        <label htmlFor="careTimeStart" className="block text-sm font-medium text-gray-700">{t('locationModal.careTimeStart')}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FaClock className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="time"
                            name="careTimeStart"
                            value={locationData.careTimeStart}
                            onChange={handleInputChange}
                            pattern="[0-9]{2}:[0-9]{2}"
                            placeholder="HH:MM"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="flex-1">
                        <label htmlFor="careTimeEnd" className="block text-sm font-medium text-gray-700">{t('locationModal.careTimeEnd')}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FaClock className="h-5 w-5 text-gray-400" />
                          </div>
                          <input
                            type="time"
                            name="careTimeEnd"
                            value={locationData.careTimeEnd}
                            onChange={handleInputChange}
                            pattern="[0-9]{2}:[0-9]{2}"
                            placeholder="HH:MM"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Facilities and Services */}
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="text-md font-semibold mb-3">{t('locationModal.facilitiesAndServices')}</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="pedagogicalConcept" className="block text-sm font-medium text-gray-700">{t('locationModal.pedagogicalConcept')}</label>
                      <textarea
                        id="pedagogicalConcept"
                        name="pedagogicalConcept"
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full text-base border border-gray-300 rounded-md p-2"
                        placeholder={t('locationModal.pedagogicalConceptPlaceholder')}
                        value={locationData.pedagogicalConcept}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="isAccessible"
                          name="isAccessible"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          checked={locationData.isAccessible}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="isAccessible" className="font-medium text-gray-700">{t('locationModal.isAccessible')}</label>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="hasOutdoorArea"
                          name="hasOutdoorArea"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          checked={locationData.hasOutdoorArea}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="hasOutdoorArea" className="font-medium text-gray-700">{t('locationModal.hasOutdoorArea')}</label>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label htmlFor="cateringOption" className="block text-sm font-medium text-gray-700">{t('locationModal.cateringOption')}</label>
                      <select
                        id="cateringOption"
                        name="cateringOption"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md p-2"
                        value={locationData.cateringOption}
                        onChange={handleInputChange}
                      >
                        <option value="">{t('locationModal.selectOption')}</option>
                        <option value="inHouse">{t('locationModal.inHouseCatering')}</option>
                        <option value="external">{t('locationModal.externalCatering')}</option>
                        <option value="none">{t('locationModal.noCatering')}</option>
                      </select>
                    </div>

                    <div>
                      <label htmlFor="closingDays" className="block text-sm font-medium text-gray-700">{t('locationModal.closingDays')}</label>
                      <input
                        type="number"
                        name="closingDays"
                        id="closingDays"
                        min="0"
                        max="365"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-base border-gray-300 rounded-md p-2"
                        placeholder={t('locationModal.closingDaysPlaceholder')}
                        value={locationData.closingDays}
                        onChange={handleInputChange}
                      />
                    </div>


                    <div>
                      <label htmlFor="additionalServices" className="block text-sm font-medium text-gray-700">{t('locationModal.additionalServices')}</label>
                      <textarea
                        id="additionalServices"
                        name="additionalServices"
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full text-base border border-gray-300 rounded-md p-2"
                        placeholder={t('locationModal.additionalServicesPlaceholder')}
                        value={locationData.additionalServices}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="mt-5 sm:mt-6">
            <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm"
                onClick={handleSave}
              >
                {t('locationModal.save')}
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={onClose}
              >
                {t('locationModal.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;

