import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Find from './find/Find';
import Packages from './Packages';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../config';
import { Loader, CheckCircle, Circle, AlertCircle, MessageCircle, X } from 'lucide-react';
import SimpleHeader from './SimpleHeader';

const DEV_MODE = config.DEV_MODE === 'true';

const Orders = () => {
  const { t, i18n } = useTranslation();
  const [saveSelections, setSaveSelections] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showFind, setShowFind] = useState(false);
  const [showPackages, setShowPackages] = useState(false);
  const findRef = useRef(null);
  const packagesRef = useRef(null);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userProgress, setUserProgress] = useState({
    packageSelected: false,
    kitasSelected: false,
  });
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [orderStatus, setOrderStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    setVideoSrc(`${process.env.PUBLIC_URL}/${i18n.language === 'de' ? 'Maggie.webm' : 'maggie_english.webm'}`);
  }, [i18n.language]);

  useEffect(() => {
    const initializeOrder = async () => {
      const params = new URLSearchParams(location.search);
      const urlUUID = params.get('UUID');
      const cookieData = Cookies.get('formSubmissionData');

      if (urlUUID) {
        // If UUID is in URL, update the cookie
        if (cookieData) {
          const updatedCookieData = { ...JSON.parse(cookieData), UUID: urlUUID };
          Cookies.set('formSubmissionData', JSON.stringify(updatedCookieData));
        } else {
          Cookies.set('formSubmissionData', JSON.stringify({ UUID: urlUUID }));
        }
      } else if (!cookieData) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    };

    initializeOrder();
  }, [location.search]);

  useEffect(() => {
    fetchOrderStatus();
  }, []);

  const handleFindKitas = () => {
    setShowFind(!showFind);
    setTimeout(() => {
      if (findRef.current) {
        findRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleShowPackages = () => {
    console.log('Selected facilities before opening Packages:', selectedFacilities);
    setShowPackages(!showPackages);
    setTimeout(() => {
      if (packagesRef.current) {
        packagesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleFinishSelecting = async () => {
    setIsLoading(true);
    if (saveSelections) {
      try {
        await saveSelections();
      } catch (error) {
        console.error('Error saving selections:', error);
        setIsLoading(false);
        return;
      }
    }
    await updateFacilities(selectedFacilities);
    setShowFind(false);
    setShowPackages(true);
    setTimeout(() => {
      if (packagesRef.current) {
        packagesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleRemoveSelection = () => {
    setShowModal(true);
  };

  const confirmRemoveSelection = async () => {
    setShowModal(false);
    setIsLoading(true);
    await updateFacilities([]);
  };

  const updateFacilities = async (facilities) => {
    const cookieData = Cookies.get('formSubmissionData');
    const selectedLocationsCookie = Cookies.get('selectedLocations');
    if (cookieData && selectedLocationsCookie) {
      const { UUID, userId } = JSON.parse(cookieData);
      if (!userId) {
        console.error('User ID not found in cookie data');
        setIsLoading(false);
        return;
      }
      try {
        const apiEndpoint = DEV_MODE
          ? 'http://localhost:8000/user_location'
          : 'https://api.app.kita.kids/user_location';

        const selectedLocations = JSON.parse(selectedLocationsCookie);

        const response = await axios.post(`${apiEndpoint}/${userId}/${UUID}`, 
          selectedLocations
        , {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': config.KITA_API_KEY
          }
        });
        if (response.status === 200) {
          console.log('Facilities updated successfully');
          setUserProgress(prev => ({ ...prev, kitasSelected: selectedLocations.length > 0 }));
          setSelectedFacilities(Array.from(selectedLocations));
          // Update cookie with new progress
          Cookies.set('formSubmissionData', JSON.stringify({
            ...JSON.parse(cookieData),
            kitasSelected: selectedLocations.length > 0
          }));
          
          // Refresh the order status
          await fetchOrderStatus();
        }
      } catch (error) {
        console.error('Error updating facilities:', error);
      } finally {
        setIsLoading(false);
        setShowFind(false); // Hide the Find component after selection
      }
    } else {
      console.error('No form submission data or selected locations found');
      setIsLoading(false);
    }
  };

  const fetchOrderStatus = async () => {
    const cookieData = Cookies.get('formSubmissionData');
    if (cookieData) {
      const { UUID } = JSON.parse(cookieData);
      try {
        const apiEndpoint = DEV_MODE
          ? 'http://localhost:8000/orders'
          : `${config.API_BASE_URL}/orders`;

        const response = await axios.get(`${apiEndpoint}/${UUID}`, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': config.KITA_API_KEY
          }
        });

        if (response.status === 200) {
          const orderData = response.data;
          setOrderStatus(orderData);
          
          // Set paymentStatus to paid if businessPartner is voiio
          if (orderData.businessPartner === 'voiio') {
            setPaymentStatus({ value: 'paid' });
          } else {
            setPaymentStatus(orderData.paymentStatus);
          }
          
          let parsedLocations = [];
          if (typeof orderData.facilityIDs === 'string') {
            try {
              // Try to parse as JSON
              const parsedData = JSON.parse(orderData.facilityIDs);
              if (Array.isArray(parsedData)) {
                // Handle array of objects format
                parsedLocations = parsedData.map(facility => ({
                  slug: facility.slug || facility.name.toLowerCase().replace(/\s+/g, '-'),
                  name: facility.name,
                  isPartner: facility.isPartner || false
                }));
              } else {
                throw new Error('Parsed data is not an array');
              }
            } catch (error) {
              // If JSON parsing fails, treat as comma-separated string
              parsedLocations = orderData.facilityIDs.split(',').map(slug => ({
                slug,
                name: slug.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                isPartner: false
              }));
            }
          } else {
            console.error('Unexpected format for facilityIDs:', orderData.facilityIDs);
          }
          
          Cookies.set('selectedLocations', JSON.stringify(parsedLocations), { expires: 30 });
          
          setUserProgress({
            packageSelected: orderData.businessPartner === 'voiio' || (orderData.paymentStatus && orderData.paymentStatus.value !== 'notPaid'),
            kitasSelected: parsedLocations.length > 0,
          });
          setSelectedFacilities(parsedLocations.map(location => location.slug));
          setUserId(orderData.id); // Set the user ID from the order data
          
          // Update the cookie with the user ID and businessPartner
          const updatedCookieData = JSON.parse(cookieData);
          updatedCookieData.userId = orderData.id;
          updatedCookieData.businessPartner = orderData.businessPartner;
          Cookies.set('formSubmissionData', JSON.stringify(updatedCookieData).replace(/\\/g, '').replace(/""""/g, '"'));

          // You might want to use the businessPartner value in your component state or logic
          // For example:
          // setBusinessPartner(orderData.businessPartner);
        }
      } catch (error) {
        console.error('Error fetching order status:', error);
      }
    }
  };

  const updateSelectedFacilities = (facilities) => {
    setSelectedFacilities(facilities);
  };

  const renderTodoItem = (title, description, completed, onClick, isKitaSelection = false, dataTestId = '') => {
    const baseClasses = "w-full text-left focus:outline-none group bg-white p-6 rounded-xl shadow-md transition duration-300";
    const completedClasses = completed ? 'border-l-4 border-green-500' : 'border-l-4 border-yellow-500 transform hover:-translate-y-1 cursor-pointer';
    
    const selectedLocations = Cookies.get('selectedLocations') || '[]';
    const selectedFacilitiesCount = JSON.parse(selectedLocations).length;
    
    return (
      <div 
        className={`${baseClasses} ${completedClasses}`}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-semibold text-purple-600">{title}</h3>
          {completed ? (
            <CheckCircle className="text-green-500" size={24} />
          ) : (
            <Circle className="text-gray-400" size={24} />
          )}
        </div>
        {!completed && (
          <>
            <p className="text-sm text-gray-600">{description}</p>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              className="mt-2 bg-yellow-400 text-black font-medium py-2 px-4 rounded-md hover:bg-yellow-500 transition duration-300"
            >
              {t('orders.doNow')}
            </button>
          </>
        )}
        {completed && isKitaSelection && (
          <div className="flex items-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveSelection();
              }}
              className="mr-2 text-red-500 hover:text-red-600 transition duration-300"
              disabled={isLoading}
              title={t('orders.removeSelection')}
            >
              {isLoading ? (
                <Loader className="animate-spin h-6 w-6" />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              )}
            </button>
            <p className="text-sm text-gray-600">
              {t('orders.selectedDaycares', { count: selectedFacilitiesCount })}
            </p>
          </div>
        )}
      </div>
    );
  };

  const getOrderStatus = () => {
    if (!userProgress.packageSelected && !userProgress.kitasSelected) {
      return {
        message: t('orders.selectDaycares'),
        color: "bg-yellow-100 text-yellow-800",
        icon: <AlertCircle className="text-yellow-500" size={24} />
      };
    }

    if (!paymentStatus) {
      return {
        message: t('orders.selectPackageAndPay'),
        color: "bg-yellow-100 text-yellow-800",
        icon: <AlertCircle className="text-yellow-500" size={24} />
      };
    }

    switch (paymentStatus.value) {
      case 'notPaid':
        return {
          message: t('orders.completePayment'),
          color: "bg-red-100 text-red-800",
          icon: <AlertCircle className="text-red-500" size={24} />
        };
      case 'paid':
        if (!userProgress.kitasSelected) {
          return {
            message: t('orders.selectDaycares'),
            color: "bg-blue-100 text-blue-800",
            icon: <Circle className="text-blue-500" size={24} />
          };
        } else {
          return {
            message: t('orders.processingRequest'),
            color: "bg-green-100 text-green-800",
            icon: <CheckCircle className="text-green-500" size={24} />
          };
        }
      default:
        return {
          message: t('orders.statusUnknown'),
          color: "bg-gray-100 text-gray-800",
          icon: <AlertCircle className="text-gray-500" size={24} />
        };
    }
  };

  const currentOrderStatus = getOrderStatus();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin h-10 w-10 text-purple-600" />
      </div>
    );
  }

  return (
    <>
      <SimpleHeader />
      <div className="min-h-screen p-4 sm:p-8 pt-16">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-extrabold mb-6 text-[#020617] leading-tight">
              {t('orders.title')} <span className="text-[#9151D2]">{t('orders.titleHighlight')}</span>
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed max-w-2xl mx-auto">
              {t('orders.description')}
              <span className="font-semibold text-[#9151D2]"> {t('orders.descriptionHighlight')}</span>
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <div className="flex items-center">
                <svg className="w-6 h-6 text-[#24D0BD] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span className="text-gray-700">{t('orders.personalizedSelection')}</span>
              </div>
              <div className="flex items-center">
                <svg className="w-6 h-6 text-[#24D0BD] mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span className="text-gray-700">{t('orders.simpleProcess')}</span>
              </div>
            </div>
          </div>
          <div className={`mb-6 p-4 rounded-xl shadow-md ${currentOrderStatus.color} flex items-center justify-between`}>
            <span className="font-semibold">{currentOrderStatus.message}</span>
            {currentOrderStatus.icon}
          </div>

          <div className="space-y-6 mt-4">
            {renderTodoItem(
              t('orders.selectDaycares'),
              t('orders.selectDaycaresDescription'),
              userProgress.kitasSelected,
              handleFindKitas,
              true, // isKitaSelection
              "find-kitas-todo" // data-testid
            )}
            {showFind && (
              <div className="mt-6" ref={findRef} data-testid="find-component">
                <Find 
                  onSelectFacilities={updateSelectedFacilities} 
                  onSaveSelections={(saveFunc) => setSaveSelections(() => saveFunc)}
                  hideButton={true}
                />
                <div className="mt-4 flex justify-end space-x-4">
                  <button
                    onClick={handleFinishSelecting}
                    className="bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600 transition duration-300 flex items-center justify-center text-lg font-semibold shadow-md"
                    disabled={selectedFacilities.length === 0 || isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Loader className="animate-spin -ml-1 mr-2 h-5 w-5" />
                        {t('orders.loading')}
                      </>
                    ) : (
                      <>
                        <CheckCircle className="mr-2 h-5 w-5" />
                        {t('orders.finishSelection')}
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
            {renderTodoItem(
              t('orders.selectPackage'),
              t('orders.selectPackageDescription'),
              userProgress.packageSelected,
              handleShowPackages
            )}
          </div>
        </div>
      </div>

      {showPackages && (
        <div ref={packagesRef} className="mt-6">
          <Packages selectedFacilitiesCount={selectedFacilities} paymentStatus={paymentStatus} />
        </div>
      )}

      {/* Maggie Video */}
      <div className="max-w-4xl mx-auto px-4 py-6">
        <div className="md:w-2/3 lg:w-1/2 mx-auto">
          <video key={videoSrc} controls className="w-full rounded-lg shadow-lg">
            <source src={videoSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* WhatsApp CTA Button */}
      <div className="max-w-4xl mx-auto px-4 py-6">
        <a
          href="https://wa.me/message/M5JJAV7WFWRXK1"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-white text-green-600 border border-green-600 px-4 py-2 rounded-md hover:bg-green-50 transition duration-300 flex items-center justify-center"
        >
          <MessageCircle size={20} className="mr-2" />
          <span className="font-medium">{t('orders.needHelp')}</span>
        </a>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t('orders.removeDaycareSelection')}</h3>
              <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <p className="mb-6">{t('orders.removeDaycareConfirmation')}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('orders.cancel')}
              </button>
              <button
                onClick={confirmRemoveSelection}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
              >
                {t('orders.remove')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t('orders.removeDaycareSelection')}</h3>
              <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <p className="mb-6">{t('orders.removeDaycareConfirmation')}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('orders.cancel')}
              </button>
              <button
                onClick={confirmRemoveSelection}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
              >
                {t('orders.remove')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Orders;
