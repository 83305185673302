import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Search, Locate, Loader, Plus } from 'lucide-react';
import { MapContainer, TileLayer, Marker, useMap, Popup, useMapEvents } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { client, databases } from '../../utils/appwrite';
import { Query } from 'appwrite';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import config from '../../config';

const MAX_SELECTIONS = 20;

const DEV_MODE = config.DEV_MODE !== 'true';
const API_BASE_URL = DEV_MODE ? 'https://api.app.kita.kids' : 'http://localhost:8000';
const KITA_API_KEY = config.KITA_API_KEY;

// Benutzerdefinierte Marker-Icons
const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const defaultIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const goldenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const Find = ({ onSelectFacilities, onSaveSelections, hideButton = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [mapCenter, setMapCenter] = useState([52.520008, 13.404954]); // Berlin-Koordinaten
  const [zoom, setZoom] = useState(13);
  const [suggestions, setSuggestions] = useState([]);
  const [nearestKitas, setNearestKitas] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [newLocation, setNewLocation] = useState({ name: '', address: '' });
  const [submitMessage, setSubmitMessage] = useState('');

  const maxSelections = useMemo(() => {
    const formSubmissionData = Cookies.get('formSubmissionData');
    if (formSubmissionData) {
      try {
        const parsedData = JSON.parse(formSubmissionData);
        if (parsedData.businessPartner === 'voiio') {
          return 15;
        }
      } catch (error) {
        console.error('Error parsing formSubmissionData cookie:', error);
      }
    }
    return MAX_SELECTIONS;
  }, []);

  useEffect(() => {
    if (onSelectFacilities) {
      const selectedFacilitiesArray = Array.from(selectedLocations);
      if (JSON.stringify(selectedFacilitiesArray) !== JSON.stringify(selectedFacilities)) {
        setSelectedFacilities(selectedFacilitiesArray);
        onSelectFacilities(selectedFacilitiesArray);
      }
    }
  }, [selectedLocations, onSelectFacilities]);

  

  useEffect(() => {
    const loadUserSelections = () => {
      const storedSelections = Cookies.get('selectedLocations');
      if (storedSelections) {
        try {
          const selections = JSON.parse(storedSelections);
          const slugs = selections.map(selection => selection.slug);
          setSelectedLocations(new Set(slugs));
          console.log('Selections loaded from cookie:', slugs);
        } catch (error) {
          console.error('Error parsing stored selections:', error);
        }
      }
    };

    loadUserSelections();
  }, []);

  useEffect(() => {
    setNearestKitas(prevKitas => 
      prevKitas.map(kita => ({
        ...kita,
        isSelected: selectedLocations.has(kita.slug)
      }))
    );
  }, [selectedLocations]);


  const MapEvents = () => {
    const map = useMap();
    const [showButton, setShowButton] = useState(false);

    useMapEvents({
      moveend() {
        setShowButton(true);
      },
    });

    const handleSearch = () => {
      const center = map.getCenter();
      fetchNearestKitas(center.lat, center.lng, false);
    };

    return showButton ? (
      <div className="leaflet-bottom leaflet-center" style={{ left: '50%', transform: 'translateX(-50%)', bottom: '10px' }}>
        <div className="leaflet-control leaflet-bar">
          <button
            onClick={handleSearch}
            className="bg-[#9151d2] text-white px-2 py-1 rounded-md hover:bg-[#7b3ab5] transition duration-300 shadow-sm text-xs"
          >
            {t('find.searchInThisArea')}
          </button>
        </div>
      </div>
    ) : null;
  };

  const API_KEY = "i9-ekalT28o4Zg3nXsVfFIB4Lo1C1fguqwNF0gmq_ig";
  const KITA_API_KEY = "6745476829";
  const [userLocation, setUserLocation] = useState(null);

  const fetchNearestKitas = useCallback(async (lat, lon) => {
    const url = `${API_BASE_URL}/nearest_locations`;
    try {
      const response = await axios.post(url, 
        { lat, lon, n: 30 },
        { headers: { 'X-API-Key': KITA_API_KEY } }
      );
      if (response.status === 200 && response.data.locations) {
        setNearestKitas(prevKitas => {
          const newKitas = response.data.locations.map(kita => ({
            ...kita,
            isSelected: selectedLocations.has(kita.slug)
          }));
          if (JSON.stringify(prevKitas) !== JSON.stringify(newKitas)) {
            return newKitas;
          }
          return prevKitas;
        });
      } else {
        console.error('Unerwartetes Antwortformat:', response);
        // Hier könnten Sie dem Benutzer eine Fehlermeldung anzeigen
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der nächstgelegenen Kitas:', error);
      // Hier könnten Sie dem Benutzer eine Fehlermeldung anzeigen
    }
  }, [selectedLocations]);

  const fetchSuggestions = debounce(async (input) => {
    if (input.length < 6) {
      setSuggestions([]);
      return;
    }

    const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodeURIComponent(input)}&apiKey=${API_KEY}`;

    try {
      const response = await axios.get(url);
      setSuggestions(response.data.items);
    } catch (error) {
      console.error('Fehler beim Abrufen von Vorschlägen:', error);
    }
  }, 300);

  const validateAddress = async (address) => {
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(address)}&apiKey=${API_KEY}`;

    try {
      const response = await axios.get(url);
      if (response.data.items && response.data.items.length > 0) {
        const { position } = response.data.items[0];
        return { lat: position.lat, lng: position.lng };
      }
    } catch (error) {
      console.error('Fehler bei der Adressvalidierung:', error);
    }
    return null;
  };

  const handleAddressSearch = async (e) => {
    e.preventDefault();
    const validatedLocation = await validateAddress(address);
    if (validatedLocation) {
      setMapCenter([validatedLocation.lat, validatedLocation.lng]);
      setZoom(15);
      setUserLocation({ lat: validatedLocation.lat, lon: validatedLocation.lng });
      fetchNearestKitas(validatedLocation.lat, validatedLocation.lng);
    } else {
      console.log('Ungültige Adresse oder Geokodierung nicht möglich');
      // Hier könnten Sie dem Benutzer eine Fehlermeldung anzeigen
    }
  };

  const handleGeolocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter([latitude, longitude]);
          setZoom(15);
          setUserLocation({ lat: latitude, lon: longitude });
          console.log('Aktueller Standort:', latitude, longitude);
          fetchNearestKitas(latitude, longitude, true);
        },
        (error) => {
          console.error('Geolokalisierungsfehler:', error);
        }
      );
    } else {
      console.error('Geolokalisierung wird von diesem Browser nicht unterstützt.');
    }
  }, [fetchNearestKitas, setMapCenter, setZoom, setUserLocation]);

  useEffect(() => {
    if (selectedLocations.size === 0) {
      handleGeolocation();
    } else {
      saveUserSelections();
    }
  }, [selectedLocations, handleGeolocation]);

  useEffect(() => {
    if (nearestKitas.length > 0) {
      const bounds = L.latLngBounds(nearestKitas.map(kita => [kita.lat, kita.lon]));
      setMapCenter(bounds.getCenter());
      setZoom(13);
    }
  }, [nearestKitas]);

  const saveUserSelections = useCallback(async () => {
    try {
      const existingData = Cookies.get('selectedLocations');
      let existingLocations = existingData ? JSON.parse(existingData) : [];

      const newLocations = Array.from(selectedLocations).map(slug => {
        const kita = nearestKitas.find(k => k.slug === slug);
        return {
          slug: slug,
          name: kita ? kita.name : slug,
          isPartner: kita ? kita.isPartner : false
        };
      });

      // Preserve existing entries and add new ones without updating
      const mergedLocations = [...existingLocations];

      newLocations.forEach(newLocation => {
        if (!mergedLocations.some(loc => loc.slug === newLocation.slug)) {
          mergedLocations.push(newLocation);
        }
      });

      Cookies.set('selectedLocations', JSON.stringify(mergedLocations), { expires: 30 });
      console.log('Selection data saved to cookie:', mergedLocations);
      if (onSaveSelections) {
        await onSaveSelections();
      }
    } catch (error) {
      console.error('Error saving selections:', error);
    }
  }, [selectedLocations, nearestKitas, onSaveSelections]);

  const handleAddLocation = async (e) => {
    e.preventDefault();
    try {
      const locationWithPrefix = {
        name: `Fehlender Standort: ${newLocation.name}`,
        address: newLocation.address
      };
      const response = await fetch('https://n8n-d.kita.kids/webhook/98ee98a3-c95f-4eac-be94-142b31f1efe9', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(locationWithPrefix)
      });
      if (response.ok) {
        setSubmitMessage('Vielen Dank! Wir werden Ihre Anfrage überprüfen und den neuen Standort zur Datenbank hinzufügen.');
        setNewLocation({ name: '', address: '' });
        setTimeout(() => {
          setSubmitMessage('');
          setIsAddLocationModalOpen(false);
        }, 3000);
      } else {
        throw new Error('Server responded with an error');
      }
    } catch (error) {
      console.error('Error submitting new location:', error);
      setSubmitMessage('Es gab einen Fehler beim Senden. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <div className="max-w-full mx-auto mt-16">
      <h3 className="text-2xl font-bold text-[#020617] mb-6">{t('find.nearbyDaycares')}</h3>
      <form onSubmit={handleAddressSearch} className="mb-4">
        <div className="flex items-center space-x-2">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder={t('find.enterAddress')}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                fetchSuggestions(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddressSearch(e);
                }
              }}
              className="w-full p-2 pr-10 text-sm border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#9151d2] bg-white shadow-md"
            />
            <button 
              type="button" 
              onClick={handleAddressSearch}
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              <Search className="w-4 h-4 text-gray-500" />
            </button>
            {suggestions.length > 0 && (
              <ul className="absolute z-[1000] w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-40 overflow-y-auto shadow-md">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion.id}
                    className="p-2 text-sm hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setAddress(suggestion.address.label);
                      setSuggestions([]);
                    }}
                  >
                    {suggestion.address.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              handleGeolocation();
              console.log('Geolocation button clicked');
            }}
            className="bg-[#9151d2] text-white p-2 rounded-full hover:bg-[#7b3ab5] transition duration-300 shadow-md"
            aria-label={t('find.useCurrentLocation')}
          >
            <Locate className="w-4 h-4" />
          </button>
        </div>
      </form>
      <div className="h-64 rounded-lg overflow-hidden relative mb-4">
        <MapContainer
          key={`${mapCenter[0]}-${mapCenter[1]}-${zoom}`}
          center={mapCenter}
          zoom={zoom}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {userLocation && <Marker position={[userLocation.lat, userLocation.lon]} icon={redIcon} />}
          {nearestKitas.map((kita, index) => (
            <Marker 
              key={kita.id}
              position={[kita.lat, kita.lon]}
              icon={kita.isSelected ? greenIcon : (kita.isPartner ? goldenIcon : defaultIcon)}
            >
              <Popup>
                <h3 className="text-sm font-semibold">{kita.name}</h3>
                {kita.isPartner && (
                  <p className="text-xs text-green-600 font-semibold">
                    {t('find.partnerKita')}
                  </p>
                )}
                <p className="text-xs">{t('find.distance', { distance: kita.distance.toFixed(2) })}</p>
                <label className="text-xs flex items-center mt-1">
                  <input
                    type="checkbox"
                    checked={selectedLocations.has(kita.slug)}
                    onChange={() => {
                      setSelectedLocations(prev => {
                        const newSet = new Set(prev);
                        if (newSet.has(kita.slug)) {
                          console.log(`DEBUG: Removing location from selection: ${kita.name} (${kita.slug})`);
                          newSet.delete(kita.slug);
                          // Remove the item from the cookie
                          const storedSelections = Cookies.get('selectedLocations');
                          if (storedSelections) {
                            try {
                              let parsedSelections = JSON.parse(storedSelections);
                              parsedSelections = parsedSelections.filter(s => s.slug !== kita.slug);
                              Cookies.set('selectedLocations', JSON.stringify(parsedSelections), { expires: 30 });
                            } catch (error) {
                              console.error('Error updating stored selections:', error);
                            }
                          }
                        } else if (newSet.size < maxSelections) {
                          console.log(`DEBUG: Adding location to selection: ${kita.name} (${kita.slug})`);
                          newSet.add(kita.slug);
                        } else {
                          console.log(`DEBUG: Attempted to add more than ${maxSelections} locations`);
                          if (maxSelections === 15) {
                            alert(t('find.maxLocationsAlertVoiio', { max: maxSelections }));
                          } else {
                            alert(t('find.maxLocationsAlert', { max: maxSelections }));
                          }
                        }
                        console.log(`DEBUG: Current selection size: ${newSet.size}`);
                        return newSet;
                      });
                    }}
                    className="mr-1"
                  />
                  {t('find.addToPersonalSelection')}
                </label>
              </Popup>
            </Marker>
          ))}
          <MapEvents />
        </MapContainer>
      </div>
      <div className="mt-4 mb-6">
        <button
          onClick={() => setIsAddLocationModalOpen(true)}
          className="w-full bg-[#9151d2] text-white text-sm px-4 py-3 rounded-lg hover:bg-[#7b3ab5] transition duration-300 flex items-center justify-center shadow-md"
        >
          <Plus className="w-5 h-5 mr-2" />
          {t('find.reportMissingLocation')}
        </button>
      </div>
      <div>
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-lg font-semibold text-[#020617]">{t('find.selectedLocations')}</h4>
          <div className="flex items-center">
            {saveMessage && (
              <span className={`text-sm mr-2 ${saveMessage.includes(t('find.successfully')) ? 'text-green-600' : 'text-red-600'}`}>
                {saveMessage}
              </span>
            )}
            {!hideButton && (
              <button
                onClick={saveUserSelections}
                disabled={isSaving}
                className={`bg-[#9151d2] text-white text-sm px-3 py-1 rounded-lg hover:bg-[#7b3ab5] transition duration-300 flex items-center ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isSaving ? (
                  <>
                    <Loader className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" />
                    {t('find.saving')}
                  </>
                ) : (
                  t('find.saveMySelection')
                )}
              </button>
            )}
          </div>
        </div>
        {selectedLocations.size > 0 ? (
          <>
            <div className="space-y-1 max-h-[calc(100vh-400px)] overflow-y-auto">
              <ul className="space-y-1">
                {Array.from(selectedLocations).slice(0, 15).map((slug) => {
                  const storedSelections = Cookies.get('selectedLocations');
                  let parsedSelections = [];
                  try {
                    parsedSelections = JSON.parse(storedSelections);
                  } catch (error) {
                    console.error('Error parsing stored selections:', error);
                  }
                  const kitaData = parsedSelections.find(s => s.slug === slug);
                  return (
                    <li 
                      key={slug} 
                      className={`flex items-center justify-between p-2 rounded-lg text-sm bg-gray-100 ${kitaData && kitaData.isPartner ? 'border-l-4 border-yellow-400' : ''}`}
                    >
                      <span className="truncate flex-grow mr-2">
                        {kitaData ? kitaData.name : slug}
                        {kitaData && kitaData.isPartner && (
                          <span className="ml-2 text-xs text-yellow-600 font-semibold">
                            {t('find.partnerKita')}
                          </span>
                        )}
                      </span>
                      <button
                        onClick={() => {
                          setSelectedLocations(prev => {
                            const newSet = new Set(prev);
                            newSet.delete(slug);
                            return newSet;
                          });
                          // Remove the item from the cookie
                          const storedSelections = Cookies.get('selectedLocations');
                          if (storedSelections) {
                            try {
                              let parsedSelections = JSON.parse(storedSelections);
                              parsedSelections = parsedSelections.filter(s => s.slug !== slug);
                              Cookies.set('selectedLocations', JSON.stringify(parsedSelections), { expires: 30 });
                            } catch (error) {
                              console.error('Error updating stored selections:', error);
                            }
                          }
                        }}
                        className="text-red-500 hover:text-red-700 text-xs"
                      >
                        {t('find.remove')}
                      </button>
                    </li>
                  );
                })}
              </ul>
              {selectedLocations.size > 15 && (
                <div className="mt-4">
                  <h5 className="font-semibold text-yellow-600 mb-2">{t('find.onlyInPackage', { package: "'Gib mir Mehr!'" })}</h5>
                  <ul className="space-y-1">
                    {Array.from(selectedLocations).slice(15).map((slug) => {
                      const storedSelections = Cookies.get('selectedLocations');
                      let parsedSelections = [];
                      try {
                        parsedSelections = JSON.parse(storedSelections);
                      } catch (error) {
                        console.error('Error parsing stored selections:', error);
                      }
                      const kitaData = parsedSelections.find(s => s.slug === slug);
                      return (
                        <li 
                          key={slug} 
                          className="flex items-center justify-between p-2 rounded-lg text-sm border-2 border-yellow-400"
                        >
                          <span className="truncate flex-grow mr-2">
                            {kitaData ? kitaData.name : slug}
                            {kitaData && kitaData.isPartner && (
                              <span className="ml-2 text-xs text-yellow-600 font-semibold">
                                {t('find.partnerKita')}
                              </span>
                            )}
                          </span>
                          <button
                            onClick={() => {
                              setSelectedLocations(prev => {
                                const newSet = new Set(prev);
                                newSet.delete(slug);
                                return newSet;
                              });
                              // Remove the item from the cookie
                              const storedSelections = Cookies.get('selectedLocations');
                              if (storedSelections) {
                                try {
                                  let parsedSelections = JSON.parse(storedSelections);
                                  parsedSelections = parsedSelections.filter(s => s.slug !== slug);
                                  Cookies.set('selectedLocations', JSON.stringify(parsedSelections), { expires: 30 });
                                } catch (error) {
                                  console.error('Error updating stored selections:', error);
                                }
                              }
                            }}
                            className="text-red-500 hover:text-red-700 text-xs"
                          >
                            {t('find.remove')}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </>
        ) : (
          <p className="text-gray-500 text-sm">{t('find.noLocationsSelected')}</p>
        )}
      </div>
      <Modal isOpen={isAddLocationModalOpen} onClose={() => setIsAddLocationModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-6 text-[#020617]">{t('find.reportMissingLocation')}</h2>
        {submitMessage ? (
          <p className="text-green-600 mb-4">{submitMessage}</p>
        ) : (
          <form onSubmit={handleAddLocation} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">{t('find.daycareNameLabel')}</label>
              <input
                type="text"
                id="name"
                value={newLocation.name}
                onChange={(e) => setNewLocation({...newLocation, name: e.target.value})}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#9151d2] focus:border-transparent"
                required
              />
            </div>
            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">{t('find.addressLabel')}</label>
              <input
                type="text"
                id="address"
                value={newLocation.address}
                onChange={(e) => setNewLocation({...newLocation, address: e.target.value})}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#9151d2] focus:border-transparent"
                required
              />
            </div>
            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="px-4 py-2 bg-[#9151d2] text-white rounded-md hover:bg-[#7b3ab5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#9151d2]"
              >
                {t('find.reportLocation')}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default Find;
