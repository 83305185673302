import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config';
import Cookies from 'js-cookie';

const DEV_MODE = config.DEV_MODE === 'true';

const NotifyModal = ({ isOpen, onClose, message, packageName }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    if (isOpen) {
      const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
      const foundUuid = formSubmissionData.UUID;
      setUuid(foundUuid);

      if (foundUuid) {
        handleSubmit(foundUuid);
      }
    }
  }, [isOpen]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = useCallback(async (identifier) => {
    if (!identifier && !validateEmail(email)) {
      setEmailError(t('packages.invalidEmail'));
      return;
    }

    setIsSubmitting(true);

    try {
      const apiEndpoint = DEV_MODE
        ? 'http://localhost:8000/notifymeOnCapacity'
        : 'https://api.app.kita.kids/notifymeOnCapacity';

      const response = await axios.post(apiEndpoint, {
        identifier: identifier || email,
        comment: packageName
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': config.KITA_API_KEY
        }
      });

      if (response.status === 200) {
        setIsRequestSent(true);
      }
    } catch (error) {
      console.error('Error sending notification request:', error);
      setEmailError(t('packages.errorSubmitting'));
    } finally {
      setIsSubmitting(false);
    }
  }, [email, packageName, t]);

  if (!isOpen) return null;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{t('packages.waitlistNotification')}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        {isRequestSent ? (
          <p className="mb-6">{t('packages.waitlistSuccess')}</p>
        ) : uuid ? (
          <p className="mb-6">{t('packages.submitting')}</p>
        ) : (
          <>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                {t('packages.emailLabel')}
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#9151D2] focus:border-[#9151D2]"
                placeholder={t('packages.emailPlaceholder')}
              />
              {emailError && <p className="mt-1 text-sm text-red-600">{emailError}</p>}
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
                className="px-4 py-2 bg-[#9151D2] text-white rounded hover:bg-[#7b3ab5] transition duration-300 disabled:opacity-50"
              >
                {isSubmitting ? t('packages.submitting') : t('packages.submit')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotifyModal;
